import { useCallback, useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useRouter } from 'next/router';

import { AlgoliaSearchContext } from 'Contexts/algoliaSearchContext';
import { sendCoralogixLog } from 'Utils/coralogix/log';
import fetchWrapper from 'Utils/fetchWrapper';
import { getCloseByCities } from 'Utils/stateUtils';

import { receiveCloseByCities } from 'Actions/city';

import { LOG_CATEGORIES, LOG_LEVELS } from 'Constants/constants';

type Props = {
	language?: string;
	shouldFetchClosebyCities: boolean;
	isNativeSearch?: boolean;
};

type SelectorState = {
	closeByCities: any[];
};

const useFetchCloseByCities = ({
	shouldFetchClosebyCities,
	language = 'en',
	isNativeSearch = false,
}: Props) => {
	const dispatch = useDispatch();
	const { citySearchIndex } = useContext(AlgoliaSearchContext);
	const [hasFetchedResults, setHasFetchedResults] = useState(false);
	const [nativelySearchedCloseByCities, setNativelySearchedCloseByCities] =
		useState([]);

	const { closeByCities }: SelectorState = useSelector(state => ({
		closeByCities: getCloseByCities(state) || [],
	}));

	const router = useRouter();

	const fetchCloseByCities = useCallback(async () => {
		try {
			const { hits }: { hits: any } = await citySearchIndex.search('', {
				aroundLatLngViaIP: true,
				aroundRadius: 'all',
				hitsPerPage: 5,
			});
			setHasFetchedResults(true);
			dispatch(receiveCloseByCities(hits));
		} catch (error) {
			sendCoralogixLog({
				title: `Error fetching closeby cities`,
				severity: LOG_LEVELS.ERROR as any,
				methodName: 'fetchCloseByCities',
				metaData: error,
				category: LOG_CATEGORIES.API_ERROR,
			});
		}
	}, [citySearchIndex, dispatch]);

	const nativelyFetchCloseByCities = useCallback(async () => {
		try {
			const url = `${
				process.env.NEXT_PUBLIC_API_CDN_BASE_URL_SEARCH
			}/api/v3/search/nearby-cities/?limit=5&language=${language.toLowerCase()}`;

			fetchWrapper(url)
				.then(res => res.json())
				.then(res => {
					setHasFetchedResults(true);
					setNativelySearchedCloseByCities(res.cities);
				})
				.catch(() => {
					setHasFetchedResults(true);
				});
		} catch (error) {
			sendCoralogixLog({
				title: 'CLOSEBY_CITIES_FETCH_FAILED',
				severity: LOG_LEVELS.ERROR as any,
				methodName: 'nativelyFetchCloseByCities',
				metaData: error,
				category: LOG_CATEGORIES.API_ERROR,
			});
		}
	}, []);

	useEffect(() => {
		if (hasFetchedResults || !shouldFetchClosebyCities) return;
		(isNativeSearch ? nativelyFetchCloseByCities : fetchCloseByCities)();
	}, [
		fetchCloseByCities,
		nativelyFetchCloseByCities,
		hasFetchedResults,
		shouldFetchClosebyCities,
	]);

	return {
		closeByCities: isNativeSearch
			? nativelySearchedCloseByCities
			: closeByCities,
	};
};

export default useFetchCloseByCities;
